import Table, { TableProps } from '@mui/material/Table';
import { FC, PropsWithChildren } from 'react';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { useTheme } from '@mui/material';

export const CustomTable: FC<PropsWithChildren<TableProps>> = props => {
    const theme = useTheme();
    const { sx } = props;

    const isMobile = useIsMobile();

    const borderRadius = isMobile ? 0 : '20px';

    return (
        <Table
            sx={{
                '& .MuiTableCell-root': {
                    border: 'none',
                },
                // '& .MuiTableCell-head': {
                //     color: theme.palette.text.secondary,
                //     py: 1,
                // },
                '& .MuiTableCell-body': {
                    py: {
                        xs: 1,
                        sm: 2,
                    },
                },
                '& .MuiTableBody-root': {
                    borderRadius,
                    boxShadow: { xs: 0, sm: 1 },
                    backdropFilter: 'blur(20px)',
                },
                '& .MuiTableRow-root': {
                    '&:nth-of-type(odd)': {
                        backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    '&:first-child': {
                        'td:first-child': {
                            borderTopLeftRadius: borderRadius,
                        },
                        'td:last-child': {
                            borderTopRightRadius: borderRadius,
                        },
                    },
                    '&:last-child': {
                        'td:first-child': {
                            borderBottomLeftRadius: borderRadius,
                        },
                        'td:last-child': {
                            borderBottomRightRadius: borderRadius,
                        },
                    },
                },
                ...sx,
            }}
        >
            {props.children}
        </Table>
    );
};
