import { injectable } from 'ioc';
import { makeAutoObservable } from 'mobx';
import { AppBackground } from '../enums/app-background';

@injectable()
export class AppStore {
    isMobileBetSlipVisible = false;
    headerHeight = 0;
    footerHeight = 0;
    appBackground = AppBackground.Default;

    constructor() {
        makeAutoObservable(this);
    }

    setAppBackground = (appBackground: AppBackground) => {
        this.appBackground = appBackground;
    };

    setIsMobileBetSlipVisible = (isVisible: boolean) => {
        this.isMobileBetSlipVisible = isVisible;
    };

    setHeaderHeight = (height: number) => {
        this.headerHeight = height;
    };

    setFooterHeight = (height: number) => {
        this.footerHeight = height;
    };
}
