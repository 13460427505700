import { AppBackground } from '../enums/app-background';
import AppDefaultBackgroundImage from '../../../assets/redesign/images/app-background.png';
import RankedPlayBackgroundImage from '../../../assets/redesign/images/ranked-play-background.jpeg';

export const mapAppBackgroundToImage: Record<AppBackground, { src: string; filter?: string }> = {
    [AppBackground.Default]: { src: AppDefaultBackgroundImage },
    [AppBackground.RankedPlay]: {
        src: RankedPlayBackgroundImage,
        filter: 'blur(10px) opacity(0.7)',
    },
};
