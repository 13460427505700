import { observer } from 'mobx-react';
import { Avatar, LinearProgress, Stack } from '@mui/material';
import { ColoredBorder } from '../../common/components/colored-border/colored-border';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { useDependencies } from 'ioc';
import { RankedPlayStore } from '../stores/ranked-play-store';
import { AuthStore } from '../../common/stores/auth-store';
import { Rank } from './rank';
import RankedProfileBackground from '../../../assets/redesign/images/ranked-play-profile-background.jpeg';
import Box from '@mui/material/Box';

export const RankedPlayProfile = observer(() => {
    const [{ currentSubRank, nextSubRank }, { me }] = useDependencies(RankedPlayStore, AuthStore);

    if (!me || !currentSubRank) {
        return null;
    }

    return (
        <Box
            sx={{
                'borderRadius': 2,
                'p': 3,
                'position': 'relative',
                'width': '100%',
                'overflow': 'hidden',
                ':before': {
                    content: `""`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${RankedProfileBackground})`,
                    backgroundSize: 'cover',
                    filter: 'blur(2px)',
                    backgroundPosition: 'center',
                    zIndex: -1,
                },
            }}
        >
            <Stack alignItems="center">
                <ColoredBorder toTop borderSize="2px" borderRadius={100}>
                    <Avatar sx={{ width: 52, height: 52 }} src={me.avatarUrl} />
                </ColoredBorder>
                <Typography fontSize={24} mt={2} mb={3} sx={{ wordBreak: 'break-all' }}>
                    {me.username}
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={nextSubRank ? 'space-between' : 'center'}
                    width="100%"
                    mb={2}
                >
                    <Rank rank={currentSubRank} />
                    {!!nextSubRank && <Rank rank={nextSubRank} />}
                </Stack>
                {me.rankInfo && (
                    <LinearProgress
                        color="secondary"
                        sx={{ width: '100%' }}
                        variant="determinate"
                        value={
                            me.rankInfo.currentScore /
                            (me.rankInfo.maxScore ?? me.rankInfo.currentScore)
                        }
                    />
                )}
            </Stack>
        </Box>
    );
});
