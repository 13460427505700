import { FC } from 'react';
import { observer } from 'mobx-react';
import { RankedPlayRoutes } from '../utils/ranked-play-routes';
import { Stack, Tab, Tabs } from '@mui/material';
import { Link as RouterLink, NavLink, useParams } from 'react-router-dom';
import { useScreenSize } from '../../common/hooks/use-is-mobile';
import Button from '@mui/material/Button';
import {
    customNeutral,
    customSecondary,
    customTertiary,
} from '../../redesigned-modules/root/components/theme-provider/colors';

export const RankedPlayMenuItems: FC = observer(() => {
    const { isDesktop } = useScreenSize();

    const { tab } = useParams();

    if (isDesktop) {
        return (
            <Stack alignItems="start" px={5} spacing={2} width="100%">
                {Object.entries(RankedPlayRoutes).map(([label, route]) => (
                    <NavLink to={`../${route}`} key={label}>
                        {({ isActive }) => (
                            <Button
                                sx={{
                                    fontSize: 24,
                                    borderRadius: 0,
                                    px: isActive ? 4 : 2,
                                    fontWeight: 500,
                                    borderTopLeftRadius: 60,
                                    borderBottomRightRadius: 60,
                                    background: isActive
                                        ? `linear-gradient(to top, ${customSecondary[300]}, ${customTertiary[100]}) border-box`
                                        : 'transparent',
                                    color: isActive ? customNeutral[950] : 'white',
                                }}
                            >
                                {label}
                            </Button>
                        )}
                    </NavLink>
                ))}
            </Stack>
        );
    }

    return (
        <Tabs
            scrollButtons="auto"
            variant="scrollable"
            value={tab}
            orientation={isDesktop ? 'vertical' : 'horizontal'}
            sx={
                isDesktop
                    ? {
                          '& .MuiTabs-indicator': {
                              left: 0,
                              right: 'unset',
                          },
                      }
                    : undefined
            }
        >
            {Object.entries(RankedPlayRoutes).map(([label, route]) => (
                <Tab
                    key={label}
                    label={label}
                    component={RouterLink}
                    to={`../${route}`}
                    value={route}
                />
            ))}
        </Tabs>
    );
});
