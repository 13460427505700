import { FC, Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { NotificationModel, NotificationTypeEnum } from '../../common/api/api';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDependencies } from 'ioc';
import { UserNotificationsStore } from '../stores/user-notifications-store';
import { timeAgo } from '../../../utils/date-time';

interface UserNotificationProps {
    notification: NotificationModel;
    index: number;
}

const mapNotificationNameToType: Record<NotificationTypeEnum, string> = {
    [NotificationTypeEnum.FriendRequest]: 'New Friend Request',
    [NotificationTypeEnum.GameInvitation]: 'Game Invitation',
    [NotificationTypeEnum.GameLeagueInvitation]: 'League Invitation',
    [NotificationTypeEnum.Informative]: 'Information',
};

export const UserNotification: FC<UserNotificationProps> = observer(({ notification, index }) => {
    const [{ takeAction }] = useDependencies(UserNotificationsStore);
    const { hasAccept, message, type, iconUrl, isHighlighted } = notification;
    const [isLoading, setIsLoading] = useState(false);

    const getActionTaker = (decision: boolean) => () => {
        setIsLoading(true);
        takeAction(notification, decision).finally(() => setIsLoading(false));
    };

    return (
        <Box
            display="flex"
            alignItems="center"
            gap={1.5}
            sx={{
                p: 2,
                border: 'solid transparent',
                borderWidth: '2px',
                borderRadius: '8px',
                background: isHighlighted
                    ? 'linear-gradient(#191A2A, #191A2A) padding-box, linear-gradient(to right, #58F081, #2B5FD9) border-box'
                    : '',
            }}
        >
            <Avatar src={iconUrl} />
            <Box flexGrow={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                        variant="subtitle2"
                        fontSize={14}
                        fontWeight={500}
                        fontFamily="Inter"
                    >
                        {mapNotificationNameToType[type]}
                    </Typography>
                    <Typography variant="body2" color="#93A0A7" fontFamily="Inter">
                        {timeAgo(notification.createDate)}
                    </Typography>
                </Box>
                <Typography variant="body2" color="#93A0A7" fontFamily="Inter">
                    {message?.split(' ').map(word => {
                        if (word.includes('#') || word.includes('@')) {
                            return `${word} `;
                        }
                        return <Fragment key={word}>{word} </Fragment>;
                    })}
                </Typography>
                {hasAccept && (
                    <Box display="flex" gap={1.5} mt={1.5}>
                        <Button
                            sx={{
                                borderRadius: '8px',
                            }}
                            variant="contained"
                            size="small"
                            disabled={isLoading}
                            onClick={getActionTaker(true)}
                        >
                            Accept
                        </Button>
                        <Button
                            sx={{
                                borderRadius: '8px',
                            }}
                            variant="outlined"
                            color="error"
                            size="small"
                            disabled={isLoading}
                            onClick={getActionTaker(false)}
                        >
                            Reject
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
});
