import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide, useDependencies } from 'ioc';
import { GlobalLeaderboardStore } from '../../stores/global-leaderboard-store';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { alpha, Stack } from '@mui/material';
import { customNeutral } from '../../../redesigned-modules/root/components/theme-provider/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useScreenSize } from '../../../common/hooks/use-is-mobile';
import { GlobalLeaderboardPlayer } from './global-leaderboard-player';
import { ColoredBorder } from '../../../common/components/colored-border/colored-border';

export const GlobalLeaderboard = provide([GlobalLeaderboardStore])(
    observer(() => {
        const [
            {
                fetchLeaderboard,
                globalLeaderboard,
                loadStatus,
                currentPlayerRank,
                globalLeaderboardWithoutCurrentUser,
                currentPlayer,
            },
        ] = useDependencies(GlobalLeaderboardStore);

        useEffect(() => {
            fetchLeaderboard();
        }, []);

        const { isDesktop } = useScreenSize();

        let asyncList = (
            <Stack
                sx={{
                    borderRadius: 2,
                    overflow: 'hidden',
                    position: 'relative',
                    maxHeight: isDesktop ? '75dvh' : '50dvh',
                    background: alpha('#fff', 0.05),
                    backdropFilter: 'blur(20px)',
                }}
            >
                <Box
                    sx={{
                        overflowY: 'auto',
                        height: '100%',
                        px: 3,
                        py: 2,
                    }}
                >
                    <AsyncList
                        length={globalLeaderboard.length}
                        loadStatus={loadStatus}
                        onFetch={fetchLeaderboard}
                    >
                        {setLastElement => (
                            <Stack divider={<Box height={24} />}>
                                {globalLeaderboardWithoutCurrentUser.map((player, playerIndex) => (
                                    <Box
                                        key={player.id}
                                        ref={
                                            playerIndex ===
                                            globalLeaderboardWithoutCurrentUser.length - 1
                                                ? setLastElement
                                                : undefined
                                        }
                                    >
                                        <GlobalLeaderboardPlayer
                                            player={player}
                                            rank={playerIndex + 1}
                                        />
                                    </Box>
                                ))}
                            </Stack>
                        )}
                    </AsyncList>
                </Box>
                {currentPlayerRank !== undefined && currentPlayer && (
                    <ColoredBorder
                        sx={{ position: 'sticky', bottom: 0 }}
                        borderRadius="16px"
                        borderSize="1px"
                        initialBackground="transparent"
                    >
                        <Box
                            sx={{
                                px: 3,
                                py: 2,
                                background: customNeutral[750],
                                borderRadius: 'inherit',
                            }}
                        >
                            <GlobalLeaderboardPlayer
                                player={currentPlayer}
                                rank={currentPlayerRank}
                                isCurrentPlayer
                            />
                        </Box>
                    </ColoredBorder>
                )}
            </Stack>
        );

        if (isDesktop) {
            asyncList = (
                <Stack spacing={2}>
                    <Typography fontSize={32} fontWeight={600}>
                        Global Leaderboard
                    </Typography>
                    {asyncList}
                </Stack>
            );
        }

        return asyncList;
    }),
);
