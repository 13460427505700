import { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

interface ColoredBorderProps extends BoxProps {
    borderSize?: string;
    toTop?: boolean;
    active?: boolean;
    initialBackground?: string;
}

export const ColoredBorder: FC<ColoredBorderProps> = ({
    active = true,
    toTop,
    borderSize = '4px',
    sx,
    children,
    initialBackground = 'white',
    ...props
}) => {
    let background = `linear-gradient(${initialBackground}, ${initialBackground}) padding-box`;

    if (active) {
        background += `, linear-gradient(${toTop ? 'to top' : 'to bottom'}, #5BE734, #2B5FD9) border-box`;
    }

    return (
        <Box
            sx={{
                background,
                border: `${active ? borderSize : 0} solid transparent`,
                ...sx,
            }}
            {...props}
        >
            {children}
        </Box>
    );
};
